import {inject,bindingMode,bindable } from 'aurelia-framework';
import { EmployeeService } from '../../shared/services/employeeservice';
import flatpickr from "flatpickr";
import { JwtService } from '../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { TaskService } from '../../shared/services/taskservice';
import * as XLSX from 'xlsx';
import {config} from '../../shared/services/config';
import { data } from 'jquery';


@inject(EmployeeService,DialogController,JwtService,TaskService,DialogService)
export class MyTimeoffDetailsComponent{
   
    isLoading = false;
    taskViewList = [];
    pageSize = config.grid_PazeSize;
   
    statusOptions = [
        { label: 'All', value: 'All', checked: false },
        { label: 'Pending with me', value: 'PUB', checked: true},
        { label: 'Pending approval', value: 'ACT', checked: false },
    ];

    constructor(employeeServices,controller,JwtService,TaskService,DialogService){
        this.employeeServices = employeeServices;
        this.controller = controller;
        this.jwtService= JwtService;
        this.taskService = TaskService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        
        
    }
    
    attached(){

        // this.taskService.GettaskList(167)
        //     .then(data =>{
        //         this.taskViewList = data.TaskViewListData;
        //         console.log(this.taskViewList)
        //     });


        const selectedStatusList = this.statusOptions
        .filter(status => status.checked)
        .map(status => status.value);
    console.log(this.statusOptions);
            const timeoff = {
                EmployeeID:1270,
                StatusList:selectedStatusList
               
                };
                this.employeeServices.GetMyGoalSheet(timeoff).then(data=>
                    {
                        this.taskViewList = data.EmployeeCategoryList;
                    });
                        console.log(data);

    }

    save() {
        
        const selectedStatusList = this.statusOptions
        .filter(status => status.checked)
        .map(status => status.value);
    console.log(this.statusOptions);


        const timeoff = {
            EmployeeID:1270,
            StatusList:selectedStatusList
           
            };
            this.employeeServices.GetMyGoalSheet(timeoff).then(data=>
                {
                    this.taskViewList = data.EmployeeCategoryList;
                });
                    console.log(data);

                    // const exit= this.employeeServices.PostResignationRequest(data)
                    // .then(() => {
                   
                    // });


                      
// GetMyGoalSheet(data)
// {
//     return this.apiService.post('/KRA/GetMyGoalSheet',data)
//         .then(data =>

//         {
//             return data;
//         });
// }
                    
           
    }

   

     

    
}
