import {inject } from 'aurelia-framework';

import {HttpClient, json} from 'aurelia-fetch-client';

import {Router,activationStrategy} from 'aurelia-router';

import {SharedState} from '../../shared/state/sharedstate';

import {config} from '../../shared/services/config';

import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';

import {ApiService} from '../../shared/services/apiservice';




@inject(ApiService,SharedState, Router, HttpClient, Element, ValidationControllerFactory)

export class Sample {



constructor( apiService,sharedState, router, httpClient, element, controllerFactory) {

 this.apiService = apiService;

 this.sharedState = sharedState;

 this.router = router;

 this.httpClient = httpClient;

 this.element = element;

 this.controller = controllerFactory.createForCurrentScope(); 

}




 submit()

{




var employee = {

 "SalaryRevisionId":2769,
 "EmployeeID":384,

  "AppraiseeId": 0 ,

 "AppraisalLetter": "",

"DateofAppraisal": "2023-4-30",

"DaysToRemindsHR":0,

 "DepartmentId":0,

 "PositionId":0,

 "PositionLevelId":0,

 "PositionlevelEffectiveDate":"",

"RevisedCTCEffectiveDate":"2023-4-30",

"Reason":"test123",

 "NextRevisionDueDate":"2024-4-30",

"PersonID":388,

"RevisedCTC":550000,

 "VariablePay":100000,
 "TotalPay" : 600000









};




 return this.apiService.post('/Employee/PostPersonCTCRevision/',employee)

}
}