// confirmation-dialog.js
import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {LoginService} from "../../shared/services/loginservice";
import {App} from "../../app";
import {Router} from 'aurelia-router';

@inject(DialogController,LoginService,App,Router,Element)

export class ConfirmationDialog {
    isDragging = false;
    dialog;
    static inject = [Element];

    constructor(controller,loginService,App,router,element) {
        this.controller = controller;
        this.loginService= loginService;
        this.app = App;
        this.router = router;
        this.element = element;
    }
    activate(message) {
        this.message = message;
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    logout() {
        const parentDiv = event.target.parentNode.parentNode;
        if (parentDiv.classList.contains('userlogout')) {
            this.loginService.purgeAuth();
            this.app.updatePropertyValue(false);
            // Redirect to the login page
            this.router.navigateToRoute('login');
        }
        this.controller.ok();
    }
}
