import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {ProfileService} from '../../shared/services/profielservice';
import Cropper from "cropperjs";
import {JwtService} from '../../shared/services/jwtservice';
import { Router } from 'aurelia-router';
import {HeaderService} from '../../shared/services/headerservice';
import { HeaderLayout } from '../../shared/layouts/headerlayout';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from "../../shared/services/loginservice";
@inject(DialogController,ProfileService,JwtService,Router,HeaderService,HeaderLayout,ValidationControllerFactory,LoginService)
export class Uploadimagepopupcomponent {
    options = [];
    Filename='';
    filePath = '';
    fileMessage;
    cropClicked = false;
    static inject = [DialogController,Element];
    isDragging = false;
    dialog;

    constructor(controller,ProfileService,JwtService,router,HeaderService,HeaderLayout,controllerFactory,loginService) {
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService = JwtService;
        this.headerService = HeaderService;
        this.HeaderLayout=HeaderLayout;
        this.router = router;
        this.loginService = loginService;
        this.validatorFacory = controllerFactory.createForCurrentScope();
        this.rules = ValidationRules.ensure('Image')
        .required()
        .on(this);
        //this.router = router;
    }
    attached() {
        // if(this.jwtService.getToken() === undefined){
        //     this.loginService.purgeAuth();
        //     this.router.navigateToRoute('login')
        // }
      const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG and PNG files are allowed.";
                this.wrongImage=true;

            } else {
                this.fileMessage=null;
                this.wrongImage=false;
            }
        });
        this.cropper = new Cropper(this.imageElement, {
            aspectRatio: null,
            dragMode: 'crop',
            movable: true,
            zoomable: false,
            cropBoxResizable: true,
            cropBoxMovable: true,
            crop(event) {
                const croppedDataUrl = this.cropper.getCroppedCanvas().toDataURL();
                let croppedImage = new Image();
                croppedImage.src = croppedDataUrl;
                let container = document.getElementById('showImage');
                // Clear the container before appending the new image
                while (container.firstChild) {
                    container.removeChild(container.firstChild);
                }
                // Append the cropped image to the container
                container.appendChild(croppedImage);
            }
        });
    }
    cancel() {
        this.controller.cancel();
    }
    fileSelected(event) {
        const file = event.target.files[0];
        //this.file= event.target.files[0];
        if (!file.type.startsWith('image/')) {
            this.message='Please select an image file.';
            return;
        }
        const reader = new FileReader();
        this.Filename = file.name;
        reader.onload = () => {
            this.file = reader.result;
            this.imageUrl = reader.result;
            this.cropper.reset();
            this.cropper.replace(this.imageUrl);
        };
        reader.readAsDataURL(file);
    }
    crop() {
      const refreshEvent = new CustomEvent('refreshImage');

        if (!this.Image) {
            this.cropClicked = true;
            setTimeout(()=>{this.cropClicked=null},5000);
        }
      this.errors = null;
      this.validatorFacory.validate().then(result=>{
        if(result.valid && !this.wrongImage){
          const canvas = this.cropper.getCroppedCanvas({
            width: 200,
            height: 200
          });
          let croppedImage = new Image();
          croppedImage.src = canvas.toDataURL();
          const dataUrl = canvas.toDataURL('image/jpeg');
          const empid = this.jwtService.getEmployeeId();
          let data = {
              EmployeeID:empid,
              PhotoPath:dataUrl,
              PhotoName:this.Filename,
          };
          this.profileService.SavePostPersonPhotoPath(data).then(()=>{
            this.headerService.dynamicHeader(empid)
              .then(() => {
                        document.dispatchEvent(refreshEvent); // Trigger the custom event
                    });
              this.controller.cancel();
          });
        }
      }); 
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }

}
