import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import { TaskService } from '../../shared/services/taskservice';
import * as XLSX from 'xlsx';
import {config} from '../../shared/services/config';
import { data } from 'jquery';

@inject(DialogController,JwtService,TaskService,DialogService)
export class MyTimeoffDetailsComponent{
   
    isLoading = false;
    taskViewList = [];
    pageSize = config.grid_PazeSize;
   
    constructor(controller,JwtService,TaskService,DialogService){
        this.controller = controller;
        this.jwtService= JwtService;
        this.taskService = TaskService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        
        
    }
    
    attached(){

        this.taskService.GettaskList(this.empid)
            .then(data =>{
                this.taskViewList = data.TaskViewListData;
                console.log(this.taskViewList)
            });

    }

   

     

    
}
