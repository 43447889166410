import {inject,bindable, bindingMode, customAttribute } from 'aurelia-framework';
import * as tinymce from 'tinymce';
import {ValidationControllerFactory, ValidationRules,validateTrigger} from 'aurelia-validation';
import { SupportService } from '../../shared/services/supportservice';
import {JwtService} from '../../shared/services/jwtservice';
import { data } from 'jquery';
import {LoginService} from "../../shared/services/loginservice";
import {Router} from 'aurelia-router';
@inject(ValidationControllerFactory,SupportService,JwtService,LoginService,Router)
export class Support {
    support=[];
    errors = null;
    //editorContent = '';
    filePath = '';
    message;
    editor = null;
    fileMessage;
    Filename='';
    reasonErrorMessage = false;
    feedbackType=null;
    location=null;
    module=null;
    isFormSubmitted = false;
    constructor(controllerFactory,SupportService,JwtService,loginService,router){
        this.support.feedbackType=null;
        this.support.location=null;
        this.support.module=null;
        this.controller = controllerFactory.createForCurrentScope();
        this.controller.validateTrigger = validateTrigger.change; 
        this.supportService = SupportService;
        this.jwtService = JwtService;
        this.router = router;
        this.loginService = loginService;
        this.rules = ValidationRules
            .ensure('feedbackType').displayName("Feedback type").required()
            .ensure('location').required()
            .ensure('module').required()
            .ensure('editorContent').required()
            .on(this.support);

            
    }

    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.support');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
      }
      return '';
    }

    attached(){
        this.getCurrentRouteURL();
        this.empid = this.jwtService.getEmployeeId();
        this.locid = this.jwtService.getLocationId();
        tinymce.init({
            selector: 'textarea',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            setup: editor => {
                editor.on('init', () => {
                    this.editor = editor;
                    this.editorContent = '';
                });
                editor.on('change', () => {
                    this.editorContent = editor.getContent();
                });
                editor.on('keyup', () => {
                    this.reasonErrorMessage = false;
                });
            },
        });
        const fileInput = document.querySelector('input[type="file"]');
        const allowedTypes = ['image/jpeg', 'image/png','application/msword','application/pdf'];
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            const fileType = file.type;
            if (!allowedTypes.includes(fileType)) {
                this.fileMessage="Only JPEG, PNG, PDF and DOC files are allowed.";
            } else {
                this.fileMessage=null;
            }
        });
        this.isLoading = true;
        this.supportService.getLocationsByEmployeeId(this.empid).then(
            data=>{
                this.isLoading = false;
                this.locations=data;
                console.log(data);
                });
        this.supportService.getModulesData().then(
            data=>{
                this.isLoading = false;
                this.modules=Object.entries(data).map(([key, value])=>({value, key}));
            });
        this.supportService.getFeedbackTypes().then(data=>{
            this.isLoading = false;
            this.feedbackTypes = Object.entries(data).map(([key, value])=>({value, key}));
        });

      var topSidebarLinks = document.querySelectorAll('.top-sidebar-links li');
      topSidebarLinks.forEach(function(li) {
        li.addEventListener('click', function() {
          var supportItems = document.querySelectorAll('.sidebar ul li.support');
          supportItems.forEach(function(supportItem) {
            supportItem.classList.remove('sub-active');
          });
        });
      });           

    }

    locationMatcher = (a, b) => {
        return a.key === b.key;
    };
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentName = file.name;
            reader.onload = () => {
                this.file = reader.result;
            };
        }
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }
    validateField(data){
        console.log(data);
        console.log(this.editorContent);
        if(data)
        {
           console.log(data);
            this.reasonErrorMessage = false;
           this.controller.clear();

           //return;
        }
        ValidationRules
            .ensure('feedbackType').displayName("Feedback type").required()
            .ensure('location').required()
            .ensure('module').required()
            .on(this.controller);
    }
    saveRequest() {
        this.errors = null;
        if(this.fileMessage){
            return false;
        }
        this.controller.validate()
            .then(result => {
                if (result.valid) {
                    let supportData = {
                        ModuleID: this.support.module,
                        LocationID:this.support.location,
                        FeedbackTypeID:this.support.feedbackType,
                        EmployeeID:this.empid,
                        Comments:this.editorContent,
                        FilePath:this.file,
                        FileName:this.DocumentName
                    };
                    if (this.editorContent == null || this.editorContent == "" || this.editorContent == undefined) {
                        this.reasonErrorMessage = true;
                    }
                    else {
                        this.reasonErrorMessage = false;
                        this.isFormSubmitted = true;
                    this.supportService.SaveSupportForm(supportData).then(()=>{
                        console.log(supportData);
                        this.support.module='';
                        this.support.location='';
                        this.support.feedbackType='';
                        this.support.upload='';
                        this.editorContent='';
                        this.file='';
                        this.message="Request submitted successfully";
                        const errorElement = document.querySelector(".scrolltoerrorsuccess");
                        if (errorElement) {
                            errorElement.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
                            window.scrollBy(0, -200);
                        }
                        const fileInput = document.querySelector('input[type=file]');
                        if (fileInput) {
                            fileInput.value = '';
                        }
                        if (this.editor) {
                            this.editor.setContent('');
                            this.editor = null;
                        }
                        this.editorContent = '';

                        setTimeout(()=>{this.message=null},3000);
                        this.isFormSubmitted = false;
                    });
                    }
                } else {
                    this.errors = "Invalid credentials";
                   const errorElement = document.querySelector(".scrolltoerrorsuccess");
                    if (errorElement) {
                        errorElement.scrollIntoView({ behavior: "auto", block: "start", inline: "nearest" });
                        window.scrollBy(0, -200);
                    }
                }
            });    }
    detached() {
        tinymce.remove(this.editor);
    }
    resetForm(){
        this.controller.reset();
        this.support.module='';
        this.reasonErrorMessage = false;
        this.support.location ='';
        this.support.feedbackType='';
        this.support.upload='';
        this.support.comments='';
        this.editorContent='';
        this.editor.setContent('');
        this.file='';
    }
}
